import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import Carousel from '../molecules/carousel'
import NewsArticlePreview from './news-article-preview'

const NewsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const MobileView = styled.div`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: block;
  }
`

interface Article {
  id: string
  title: string
  slug: string
  previewText: string
  date: string
  isFeatured: boolean
  isSponsored?: boolean
  image: {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    fluid: any // Using any for GatsbyImageFluidProps
  }
  body: {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    json: any // Using any for Contentful RichText type
  }
}

interface Props {
  news: Array<Article>
}

const FeaturedNewsRow = (props: Props): JSX.Element => {
  const sortedArticles = props.news
    ? [...props.news].sort((a, b) => {
        // Sort sponsored articles to the front
        if (a.isSponsored && !b.isSponsored) return -1
        if (!a.isSponsored && b.isSponsored) return 1
        return 0
      })
    : []

  const articles = sortedArticles.map((article: Article, index: number) => {
    return (
      <NewsArticlePreview key={article.id} halfWidth={index < 2} {...article} />
    )
  })

  return (
    <React.Fragment>
      <NewsContainer>{articles}</NewsContainer>
      <MobileView>
        <Carousel carouselItems={articles} />
      </MobileView>
    </React.Fragment>
  )
}

export default FeaturedNewsRow
