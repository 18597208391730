import { graphql } from 'gatsby'
import get from 'lodash/get'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import FeaturedNewsRow from '../components/organisms/featured-news-row'
import NewsArticlePreview from '../components/organisms/news-article-preview'

interface Article {
  id: string
  title: string
  slug: string
  previewText: string
  date: string
  isFeatured: boolean
  isSponsored?: boolean
  image: {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    fluid: any // Using any for GatsbyImageFluidProps
  }
  body: {
    json: string
  }
}

interface Props {
  data?: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    allContentfulNewsArticle: {
      nodes: Article[]
    }
  }
  location?: {
    pathname: string
  }
}

const FeaturedContainer = styled.div`
  margin-bottom: 12.8rem;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5.2rem;
  h2 {
    margin-bottom: 0;
    margin-right: 3.2rem;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${theme.colours.gray3};
  flex: 1;
`

const News = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const featuredNews = get(props, 'data.allContentfulNewsArticle.nodes')

  const filteredFeaturedNews = featuredNews
    ? featuredNews.filter((news: Article) => news.isFeatured)
    : []

  const allArticles = featuredNews
    ? featuredNews
        .filter((news: Article) => !news.isFeatured)
        .map((article: Article) => {
          return <NewsArticlePreview fullWidth key={article.id} {...article} />
        })
    : []

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title="Stay connected with what's happening in our community" />
      <TitleContainer>
        <h2>Featured News</h2>
        <Line />
      </TitleContainer>
      <FeaturedContainer>
        <FeaturedNewsRow news={filteredFeaturedNews} />
      </FeaturedContainer>
      <TitleContainer>
        <h2>All News</h2>
        <Line />
      </TitleContainer>
      {allArticles}
    </Layout>
  )
}

export const pageQuery = graphql`
  query NewsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      edges {
        node {
          title
          slug
          heroImage {
            ...GatsbyFluidImageFields
          }
        }
      }
    }
    allContentfulNewsArticle(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        title
        slug
        previewText
        isFeatured
        isSponsored
        body {
          json
        }
        date
        image {
          ...GatsbyFluidImageFields
        }
      }
    }
  }
`

export default News
